import React, { useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../../components/Layout';
import PricingCard from '../../../components/PricingCard';
import ForthcomingCourses from '../../../components/ForthcomingCourses';

// import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../../assets/images/svg/arrow-right-red.inline.svg';
import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';
import imgPhoto_r from '../../../assets/images/dates-and-fees/Photo 1.jpg';
import iconTick from '../../../assets/images/svg/tick.svg';

import { URL_ROUTES_EN } from '../../../constants/routes';
import '../../../assets/styles/pages/dates-and-fees-page.scss';

const cardData = [
  {
    id: 1,
    courseTitle: 'Intensive Spanish ',
    courseSubtitle: '20 hours/week',
    primaryPriceName: 'DISCOUNT PRICE:',
    primaryPrice: '1560€',
    primaryPeriod: '/month',
    secondaryPriceName: 'FULL PRICE:',
    secondaryPrice: '580€',
    secondaryPeriod: '/month',
    notice: ['No registration fee', 'Coursebook costs 40€'],
    btnDates: false,
  },
  {
    id: 2,
    courseTitle: 'Semi-Intensive Spanish',
    courseSubtitle: '10 hours/week',
    primaryPriceName: 'DISCOUNT PRICE:',
    primaryPrice: '890€',
    primaryPeriod: '/month',
    secondaryPriceName: 'FULL PRICE:',
    secondaryPrice: '320€',
    secondaryPeriod: '/month',
    notice: ['No registration fee', 'Coursebook costs 40€'],
    btnDates: false,
  },

  {
    id: 3,
    courseTitle: 'Part-Time Spanish',
    courseSubtitle: '4 hours/week',
    primaryPriceName: 'DISCOUNT PRICE:',
    primaryPrice: '900€',
    primaryPeriod: '/9 months',
    secondaryPriceName: 'FULL PRICE:',
    secondaryPrice: '360€',
    secondaryPeriod: '/month',
    notice: ['No registration fee', 'Coursebook costs 40€'],
    btnDates: false,
  },
  // {
  //   id: 5,
  //   courseTitle: 'University Pathway Program',
  //   courseSubtitle: '48 weeks',
  //   primaryPriceName: 'FULL PRICE:',
  //   primaryPrice: '5198€',

  //   notice: ['No registration fee', 'Coursebook costs 40€'],
  //   btnDates: false,
  // },
];

// const cardDataEs = [
//   {
//     id: 1,
//     courseTitle: 'Curso Trinity CertTESOL',
//     courseSubtitle: 'Barcelona o Sevilla',
//     primaryPriceName: 'PRECIO ANTICIPADO:',
//     primaryPrice: '1500€',
//     secondaryPriceName: 'PRECIO TOTAL:',
//     secondaryPrice: '1650€',
//     notice:
//       'El precio anticipado se aplica si pagas la totalidad del curso con 4 semanas de anticipación.',
//     btnDates: true,
//   },
//   {
//     id: 2,
//     courseTitle: 'Part-Time Online Trinity CertTESOL Course',
//     courseSubtitle: 'Online',
//     primaryPriceName: 'EARLY BIRD PRICE:',
//     primaryPrice: '1400€',
//     secondaryPriceName: 'FULL PRICE:',
//     secondaryPrice: '1550€',
//     notice: 'Early bird price applies if you pay in full 4 weeks in advance.',
//     btnDates: true,
//   },

//   {
//     id: 3,
//     courseTitle: 'Curso para Desarrollo de Profesores',
//     courseSubtitle: 'Visa de Estudiante de 1 Año',
//     primaryPriceName: 'PRECIO ANTICIPADO:',
//     primaryPrice: '1500€',
//     primaryPeriod: '/año',
//     secondaryPriceName: 'PRECIO NORMAL:',
//     secondaryPrice: '150€',
//     secondaryPeriod: '/mes',
//     notice: 'Fecha de inicio flexible.',
//     btnDates: false,
//   },
//   // {
//   //   id: 4,
//   //   courseTitle: 'Teaching Young Learners',
//   //   courseSubtitle: 'Extension Course',
//   //   primaryPriceName: 'FULL PRICE:',
//   //   primaryPrice: '300€',
//   //   notice: 'Flexible start dates.',
//   //   btnDates: false,
//   // },
//   {
//     id: 5,
//     courseTitle: 'Developing Teacher & Spanish Course',
//     courseSubtitle: '1-Year Student visa',
//     secondaryPriceName: 'FULL PRICE:',
//     secondaryPrice: '275€',
//     secondaryPeriod: '/month',
//     primaryPriceName: 'DISCOUNT  PRICE:',
//     primaryPrice: '3000€',
//     primaryPeriod: '/year',
//     notice: 'Flexible start dates.',
//     btnDates: false,
//   },
// ];

const ActiveForthcomingCourses = ({ courseType, staticCourseTypeName, allCourses }) => {
  const [isMore, setIsMore] = useState(false);

  return (
    <div className={`inner-wrapper ${courseType === staticCourseTypeName ? 'active' : ''}`}>
      <ForthcomingCourses cardData={allCourses.slice(0, isMore ? allCourses.length : 4)} />
      <div className="notification">
        <p>
          {/* Developing Teacher course and Young Learners course have flexible start dates. Contact us
          for{' '}
          <Link to="/contact/" className="colored">
            more information.
          </Link> */}
          <FormattedMessage
            id="activeComingCourses.notice"
            values={{
              accentedLink: (chunk) => (
                <FormattedMessage id="url.contact.index">
                  {(locale) => (
                    <Link to={locale[0]} className="colored">
                      {chunk}
                    </Link>
                  )}
                </FormattedMessage>
              ),
            }}
          />
        </p>
      </div>
      {allCourses.length > 4 ? (
        <button
          onClick={() => setIsMore(!isMore)}
          className="c-btn c-btn--red-border load-more-dates"
        >
          {isMore ? (
            <FormattedMessage id="forthcomingCourses.active.showLess" />
          ) : (
            <FormattedMessage id="forthcomingCourses.active.loadMore" />
          )}
          <IconArrowRightRed />
        </button>
      ) : null}
    </div>
  );
};

const DatesAndFees = ({ data }) => {
  const localizedCardData = cardData;
  return (
    <Layout
      title={'Affordable Spanish Courses in Barcelona | 2024 Prices'}
      description={
        'Join our intensive, semi-intensive, and evening Spanish courses in vibrant Barcelona. No registration fees. Secure your spot now and start studying!'
      }
    >
      <div className="dates-and-fees-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Prices</h1>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container">
            <h2 className="second-section__title c-title-34">Course Prices 2024</h2>
            <h3 className="second-section__description mb-3 c-title-18">
              No registration or matriculation fee!
            </h3>
            <h3 className="second-section__description price-notification c-title-18">
              All prices are in Euros (€)
            </h3>
            <div className="row prices-table-row">
              <div className="col">
                <div className="table-wrapper">
                  <table>
                    <tr>
                      <th>No. of weeks</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                    </tr>
                    <tr>
                      <td>Intensive 20</td>
                      <td>320</td>
                      <td>480</td>
                      <td>580</td>
                      <td>720</td>
                      <td>860</td>
                      <td>980</td>
                      <td>1120</td>
                      <td>1260</td>
                      <td>1400</td>
                      <td>1540</td>
                      <td>1560</td>
                    </tr>
                    <tr>
                      <td>Semi-intensive 10</td>
                      <td>170</td>
                      <td>255</td>
                      <td>320</td>
                      <td>400</td>
                      <td>480</td>
                      <td>560</td>
                      <td>600</td>
                      <td>675</td>
                      <td>720</td>
                      <td>780</td>
                      <td>890</td>
                    </tr>
                    <tr>
                      <td>Extensive 4</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>360</td>
                    </tr>
                    <tr>
                      <td>Conversation class</td>
                      <td>-</td>
                      <td>-</td>
                      <td>64</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>120</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>DELE Preparation</td>
                      <td>450</td>
                      <td>620</td>
                      <td>800</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </table>
                </div>
                <div className="notes-container">
                  <p class="note c-text-14">
                    <b>Note:</b> first set of coursebooks costs 40€
                  </p>
                  <p class="note c-text-14">
                    We offer substantial discounts for longer courses, please complete our{' '}
                    <Link class="red-link" to={URL_ROUTES_EN.APPLY}>
                      enquiry form
                    </Link>{' '}
                    to get a detailed quote.
                  </p>
                </div>
              </div>
            </div>
            <div className="row prices-table-row">
              <div className="col">
                <h2 className="second-section__title c-title-34">Other Courses at ILA</h2>
                <h3 className="second-section__description price-notification c-title-18">
                  Long-Term 20 Hours/Week & Student Visa Service
                </h3>
                <div className="table-wrapper">
                  <table>
                    <tr>
                      <th>No. of months</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                    </tr>
                    <tr>
                      <td>Long-term 20</td>
                      <td>1348</td>
                      <td>1680</td>
                      <td>2010</td>
                      <td>2340</td>
                      <td>2660</td>
                      <td>2990</td>
                      <td>3310</td>
                      <td>3630</td>
                      <td>3900</td>
                    </tr>
                  </table>
                </div>

                <div className="notes-container c-section-includes">
                  <div className="block_r">
                    <h2 className="c-title-20">Includes:</h2>
                    <div className="c-benefit-list block_r__benefits">
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Expert assistance on applying for your student visa
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Guidance on preparing all the paperwork and visa application
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Special discount on health insurance</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Assistance with local bureaucracy once you arrive in Barcelona
                        </p>
                      </div>
                    </div>
                    <h2 className="c-title-20">Private Classes</h2>
                    <div className="c-benefit-list block_r__benefits">
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">35€/hour</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">300€ for 10 hours</p>
                      </div>
                    </div>
                    <h2 className="c-title-20">Business Spanish Classes</h2>
                    <div className="c-benefit-list block_r__benefits">
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">From 60€/hour</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Financing available via FUNDAE</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Contact us for a detailed quote</p>
                      </div>
                    </div>
                    <div className="block_r__btns">
                      <Link to={URL_ROUTES_EN.APPLY} className="c-btn c-btn--red">
                        Book Now
                        <IconArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              {localizedCardData.map((item) => {
                return (
                  <div key={item.id} className="col-md-6 col-12">
                    <PricingCard data={item} />
                  </div>
                );
              })}
            </div> */}
            <div className="row options">
              <div className="table-content">
                <div className="line top">
                  <p className="first">Optional Extras</p>
                  <p className="second">
                    <FormattedMessage id="dates&fees.sec2.price" />
                  </p>
                </div>
                <div className="line">
                  <p className="first">1 month flat share accommodation</p>
                  <p className="second">750€</p>
                </div>
                <div className="line">
                  <p className="first">1 Month Student Residence Accommodation</p>
                  <p className="second">1000€</p>
                </div>
                <div className="line">
                  <p className="first">
                    {' '}
                    <FormattedMessage id="dates&fees.sec2.airportPickUp" />
                  </p>
                  <p className="second">70€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.culturalActivity" />
                  </p>
                  <p className="second">10€ - 50€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.nie" />
                  </p>
                  <p className="second">150€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.service" />
                  </p>
                  <p className="second">50€</p>
                </div>
                {/* <div className="line">
                  <p className="first">
                    Spanish Taster Course <span className="toLowerCase">(6 hours)</span>
                  </p>
                  <p className="second">60€</p>
                </div>
                <div className="line">
                  <p className="first">
                    Intensive Spanish Course <span className="toLowerCase">(6 hours/week)</span>
                  </p>
                  <p className="second">
                    200€ <span className="period">/month</span>
                  </p>
                </div> */}
                <div className="line">
                  <p className="first">Additional coursebooks</p>
                  <p className="second">
                    40€
                    {/*  <span className="period">/month</span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-12 offset-xl-2">
                  <div className="content">
                    <h2 className="c-title-34">
                      <FormattedMessage id="dates&fees.sec3.title" />
                    </h2>
                    <ul className="content__list">
                      <li>350€ down payment or full payment to secure your place.</li>
                      <li>
                        Discount prices apply when you pay for the course in full when you sign up.
                      </li>
                    </ul>
                    <p className="c-text-18">
                      You can pay for your course using a credit/debit card, cash, or direct bank
                      transfer. Once you have applied and been accepted onto the course you will be
                      provided with a link in order to complete the payment.{' '}
                      <Link className="colored text-fw-600" to={URL_ROUTES_EN.TERMS}>
                        Please read our refund policy.
                      </Link>
                      {/* <FormattedMessage
                        id="dates&fees.sec3.description"
                        values={{
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.apply.terms">
                              {(locale) => (
                                <Link className="colored text-fw-600" to={locale[0]}>
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      /> */}
                    </p>
                  </div>
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgPhoto_r} alt="imgPhoto_r" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
            show_on_these_pages {
              page
            }
            location
          }
        }
      }
    }
  }
`;

export default DatesAndFees;
